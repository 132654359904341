export default {
  en: {
    title: "",
  },
  pl: {
    title: "Mentorzy i eksperci 2024",
    mentors: [
      {
        id: "marcin",
        image: require("../Mentors/images/marcin_m.jpeg").default,
        name: "Marcin Młyński",
        description:
          "Entuzjasta nowych technologii, koncepcji Internet of Things oraz świata startupów. Koordynator wielu biznesowych wydarzeń dla setek osób oraz organizator kilkudziesięciu hackathonów. Co-founder & CEO CODE:ME, jednej z największych polskich szkół programowania oraz założyciel HACKER:SPACE Trójmiasto, otwartej technologicznej przestrzeni, poświęconej tworzeniu i rozwojowi innowacyjnych projektów. Hackerspace Trójmiasto jest obecnie jedną z największych technologicznych społeczności w północnej Polsce. Uczestnik PFR School of Pioneers, realizowanego we współpracy z University of Cambridge, międzynarodowego programu dedykowanego przedsiębiorcom i wizjonerom w obszarze nowych technologii.",
        title: "CEO, CODE:ME, Founder Hackerspace Trójmiasto",
        linkedin: "https://www.linkedin.com/in/marcin-mlynski/",
      },
      {
        image: require("../Mentors/images/rsz_marek_kaminski.jpg").default,
        name: "Marek Kamiński",
        description:
          "Zdobywca biegunów, założyciel Fundacji i Kaminski Academy. Jako pierwszy na świecie zdobył oba bieguny Ziemi w ciągu jednego roku, bez pomocy z zewnątrz, za co został wpisany do Księgi Rekordów Guinnessa. Inspiruje setki tysięcy ludzi w Polsce i na świecie poprzez książki, filmy, podcasty, webinary i programy trenerskie.\n" +
          "Stworzył Kamiński Academy, której misją jest promowanie wiary w potencjał ludzki i budowanie odporności psychicznej. Daje sprawdzone narzędzia do zmiany i osiągania celów, które od 20 lat zmieniają życie dziesiątek tysięcy ludzi i pomagają w rozwoju osobistym i biznesowym.  \n" +
          "Założyciel Fundacji oraz pomysłodawca międzynarodowego programu transformacyjno-rozwojowego Life Plan Academy dla młodzieży w wieku 14-17 lat, którego celem jest zmiana jakości życia, zapobieganie depresji i budowa własnej wartości, poprzez naukę osiągania indywidualnych celów.\n" +
          "<br/><br/>Aby dowiedzieć się więcej odwiedź: <br/>" +
          "<a href='https://marekkaminskiacademy.com/'>Kaminski Academy</a><br/>" +
          "<a href='https://fundacjamarkakaminskiego.com/'>Fundacja Marka Kaminskiego</a>",
        title:
          "Zdobywca biegunów, Innowator, Twórca Metody Biegun, Kaminski Academy",
        linkedin: "https://pl.linkedin.com/in/kaminskimarek1"
      },
      {
        image: require("../Mentors/images/sergiuszr.png").default,
        name: "Sergiusz Ryczel",
        description:
          "Uczę zachowania przed kamerą, na scenie, w kontakcie z mediami📺 Generalnie pomagam i uczę mówić tak by było ciekawie i by przekazać to co najważniejsze. By nie gubić swoich szans. ",
        title:
          "Trener Medialnych Wystąpień Publicznych🎤Dwujęzyczny Konferansjer🇬🇧 Mówca/Keynote Speaker🎙Konsultant Video Marketingu(EB,EA)🎥Konsultant Marki Osobistej🎩",
        linkedin: "https://www.linkedin.com/in/sergiusz-ryczel/",
      },
      {
        id: "jaroslaw",
        image: require("../Mentors/images/rsz_jaroslaw_p.jpg").default,
        name: "Jarosław Parzuchowski",
        description:
          "Prezes Zarządu Klastra Interizon, największej na Pomorzu instytucji otoczenia biznesu z branży ICT. Pełni także funkcję Przewodniczącego Rady Inteligentnej Specjalizacji Pomorza „Technologie Interaktywne w środowisku nasyconym informatycznie”. Jest członkiem dwóch interdyscyplinarnych zespołów badawczych w ramach Programu Operacyjnego Polska Cyfrowa, realizujących systemy informatyczne dla branży medycznej m.in. narzędzia do tworzenia modeli 3D z danych radiologicznych.",
        title: "CEO, Interizon: Pomorski Klaster ICT",
        linkedin: "https://www.linkedin.com/in/jaroslaw-parzuchowski-3b37b8a1",
      },

    //   {
    //     id: "miroslaw",
    //     image: require("../Mentors/images/rsz_mirek_p.jpg").default,
    //     name: "Mirosław Pałysiewicz",
    //     description:
    //       "Posiada ponad 16 lat doświadczenia w obszarze zapewnienia jakości oprogramowania. Miał okazję uczestniczyć w różnych projektach informatycznych od małych zespołów skupionych na portalach z grami online przez aplikacje webowe z zakładami sportowymi czy zakupami online po złożone systemy z sektora energetycznego. Obecnie pracuje na stanowisku Test Menadżera w sektorze informacji marketingowej. Od 2012 roku zaangażowany również w szkolenia innych. Jego specjalnością są środowiska testowe, przygotowywanie testów i scenariuszy testowych, a także testowanie responsywnych stron mobilnych. Hobbystycznie zajmuje się technologią druku 3D i IoT. Zaangażowany w działalność trójmiejskiego Hackerpasce’u.",
    //     title: "Członek Hackerspace Trójmiasto & IT Test Manager, EPAM",
    //     linkedin: "https://www.linkedin.com/in/mirosław-pałysiewicz-24a0931b",
    //   },
    //   {
    //     id: "js",
    //     image: require("../Mentors/images/jasminaz.png").default,
    //     name: "Jaśmina Zwierz",
    //     description: "Project manager zespołu ds. wsparcia przedsiębiorczości i innowacyjności w Dziale Parków Naukowo-Technologicznych w Pomorskiej Specjalnej Strefie Ekonomicznej Sp. z o.o.  Absolwentka prawa międzynarodowego na Uniwersytecie w Maastricht w Holandii. Obecnie zajmuje się wspieraniem start-upów  w rozwoju projektów badawczo-rozwojowych poprzez świadczenie i organizację usług doradczych w zakresie doradztwa biznesowego, komercjalizacji badań oraz zarządzania w przedsiębiorstwie. Od 2016 r. tworzy przestrzeń do wymiany informacji pomiędzy środowiskiem start-upowym, biznesem i naukowcami w Gdańskim Parku Naukowo-Technologicznym, szczególnie poprzez organizację konferencji. Wiedzę i doświadczenie zdobyte na podyplomowych studiach menadżerskich w Szkole Głównej Handlowej w Warszawie wykorzystuje jako mentor biznesowy, koordynator programowy wydarzeń, przewodniczący komisji oceniającej przedsiębiorstwa do Gdańskiego Parku, a także juror w konkursach dla start-upów.",
    //     title: "Project Manager, Gdański Park Naukowo-Technologiczny",
    //     linkedin: "https://www.linkedin.com/in/ja%C5%9Bmina-zwierz",
    //   },
    //   {
    //     id: "magdalenad",
    //     image: require("../Mentors/images/magdad.png").default,
    //     name: "Magdalena Drożdż",
    //     description:
    //       "Kreuje i dostarcza procesy biznesowe dla klientów. Współtworzy digitalowe programy rozwojowe w zakresie budowania odporności psychicznej i odkrywania ludzkiego potencjału. Posiada wieloletnie doświadczenie w prowadzeniu różnorodnych projektów, sprzedaży, obsłudze klienta, marketingu. Ceni długofalowe relacje i współpracę opartą na zaufaniu. \n" +
    //       "Od lat chętnie angażuje się w ważne inicjatywy społeczne jako Mentor w programie TopYoung100, czy tworzenie Uniwersytetu Trzeciego Wieku w Fundacji Komunikacji Międzykulturowej The Link.",
    //     title:
    //       "Project Manager, Trener Metody Biegun, Kaminski Academy",
    //     linkedin: "https://www.linkedin.com/in/magdalena-drozdz/",
    //   },
    //   {
    //     id: "kacperk",
    //     image: require("../Mentors/images/kacperk.jpg").default,
    //     name: "Kacper Kostecki",
    //     description:
    //       "Na co dzień Linux engineer, który stara się aby infrastruktura zawsze była \"up and running\". Prowadzi firmę, która pomaga się odnaleźć zawodowo w ogromnym świecie IT poprzez dopasowywanie przyszłych pracowników wymagań klientów. Zielony dres i zielony Jeep to jego znaki rozpoznawcze. Zawsze otwarty na nowe doświadczenia i wyzwania.",
    //     title: "Linux Engineer",
    //     linkedin: "https://www.linkedin.com/in/%E2%9A%9B%EF%B8%8F-kacper-%F0%9F%92%BB-kostecki-236250110/",
    //   },
      {
        id: "tomaszb",
        image: require("../Mentors/images/tomaszb.jpg").default,
        name: "Tomasz Bogus",
        description:
          "Od ponad 15 lat pomaga budować innowacyjność dzięki technologii i analizie danych. Tworzył i wdrażał jedne z pierwszych rozwiązań IoT (M2M) dla firm takich jak Saur Neptun Gdańsk, Instytutu Energetyki, Zarząd Transportu Miejskiego, Urząd Morski, Grupa Energa, PKP. Ekspert w obszarze sprzedaży i marketingu na rynku USA. Co-founder startupu Effictory (Industry 4.0 - Smart Factory). Absolwent wydziału ETI Politechniki Gdańskiej.",
        title: "Head of Business Development, Scalac",
        linkedin: "https://www.linkedin.com/in/tomaszbogus/",
      },
      {
        id: "jakubw",
        image: require("../Mentors/images/jakubw.png").default,
        name: "mgr inż. Jakub Wilczyński",
        description:
          "Absolwent wydziału Grafiki na Akademii Sztuk Pięknych w Gdańsku, specjalność Grafika Projektowa i Grafiki na PJATK w Gdańsku, gdzie obronił się z wyróżnieniem. Inż. Architektury. W PJATK prowadzi zajęcia z Liternictwa, Fotografii oraz Animacji. Obecnie pracuje jako UX i UI designer i współprowadzi studio projektowe. Z zamiłowania muzyk i akwarysta.",
        title: "Wykładowca na Wydziale Sztuki Nowych Mediów, PJATK Gdańsk",
        linkedin: " ",
      },
    //   {
    //     id: "arkadiuszs",
    //     image: require("../Mentors/images/arkadiuszs.png").default,
    //     name: "Arkadiusz Śmigielski",
    //     description:
    //       "Od 25 lat przedsiębiorca skoncentrowany na budowaniu nowoczesnych firm z zasadami (10 zasad OptiNav, 5 wartości Bitfold), w obszarze zaawansowanych technologii. Koncentrując się na celach i samorozwoju (autor e książki „Podążaj za marzeniami”), ostatnie 19 lat spędził tworząc i rozwijając firmę OptiNav, zajmującą się analizą i przetwarzaniem obrazu 2D i 3D w medycynie, kosmosie i przemyśle, a od 1,5 roku buduje zespół firmy Bitfold R&D, gdzie powstaje sprzętowy portfel dla technologii blochchain. Nauczyciel akademicki, aktywnie angażujący się w budowę systemu kształcenia zawodowego. Wykładowca studiów MBA. Programowanie w LabVIEW jest jedną z jego pasji (NI Certified Professional Instructor), prócz tańca (instruktor salsy) i windsurfingu.<br/>" +
    //       "Absolwent elektroniki na Wojskowej Akademii Technicznej. Absolwent Management oraz Elite Leadership Program na ICAN Institute HRBR. Absolwent Professional  Speakers Academy, Londyn.<br/>" +
    //       "Prezes zarządu Bitfold R&D, wcześniej prezes zarządu OptiNav (grupa Carl Zeiss). Członek zarządu Słupskiej Izby Przemysłowo Handlowej. Członek Komitetu Nadzorczego Izby Zaangażowanego Biznesu. Członek Rady Akademii Pomorskiej. Ekspert Narodowego Centrum Badań i Rozwoju.<br/>",
    //     title: "Chief Executive Officer, Co-founder, OptiNav, Bitfold",
    //     linkedin: "https://www.linkedin.com/in/smigielski/",
    //   },
      {
        id: "bartekk",
        image: require("../Mentors/images/bartekk.png").default,
        name: "Bartek Kotowicz",
        description:
          "Pomaga zrozumieć polskie marki. Twórca i wieloletni dyrektor agencji brandingu strategicznego Engram. Ekspert w obszarze marek korporacyjnych i osobistych, strategii marek oraz brandingu. Działa na rzecz polskiego brandingu usprawniając procesy brandingowe i rebrandingowe. Fundator i członek zarządu IBRA Instytutu Brandingowego. Inicjator i współautor Przewodnika Brandingowego. Propagator polskich marek – autor projektu „126 polskich marek”. Członek jury najważniejszych konkursów branżowych (KTR, Rebranding Roku, Projekt Roku STGU). Ułatwia poruszanie się po polskim brandingu za sprawą wystąpień, wykładów, szkoleń, konsultacji i programów mentoringowych.",
        title: "Head of brand, Semcore, członek zarządu, IBRA Instytut Brandingowy",
        linkedin: "https://www.linkedin.com/in/bartekkotowicz/",
      },
    //   {
    //     id: "wojciechh",
    //     image: require("../Mentors/images/wojciechh.jpg").default,
    //     name: "Wojciech Herisz",
    //     description:
    //       "Na co dzień Tech Lead z programistycznym zacięciem, doskonale łączy technologię z ludzkim podejściem. Jego pasja to psychologia evidence-based, dzięki której skupia się na ludziach jako największej wartości w organizacji. Wykorzystując wiedzę z zakresu psychologii, pomaga zrozumieć, dlaczego działamy w taki a nie inny sposób, prowadząc ludzi do sukcesów. Autor szkoleń i warsztatów z kompetencji zarówno miękkich jak i technicznych.",
    //     title: "Tech Lead, autor szkoleń i warsztatów",
    //     linkedin: "https://www.linkedin.com/in/wojciech-herisz/",
    //   },
      {
        id: "nataliap",
        image: require("../Mentors/images/nataliap.jpg").default,
        name: "Natalia Pawlak",
        description:
          "Od czasów wczesnoszkolnych wiązała swoją przyszłość ze środowiskiem IT. Marzenie to zrealizowała studiując na Politechnice Poznańskiej na kierunku Informatyka. Swoją zawodową wędrówkę rozpoczęła kilka lat temu. Na początku było to testowanie, ale gdzieś zawsze pojawiały się stanowiska związane również z koordynacją pracy oraz zarządzaniem ludźmi. Posiada certyfikacje z zakresu testowania jak i prowadzenia projektów. Stawia na komunikatywność i indywidualne oraz grupowe docenianie współpracowników, ze wskazaniem ich mocnych stron i odpowiednim ukierunkowaniem. W swoim zespole stawia na relacje partnerskie bazujące nie tylko na autorytecie przełożonego, ale również rodzinnej atmosferze. W wolnym czasie czyta książki kryminalne lub ogląda seriale. Ponadto jak tylko pogoda pozwala wyrusza na spacery ze swoimi psiakami.\n" +
          "\nW 2020 roku razem z Karoliną Pawłowską stworzyła markę Fun with bugs. Pod tym szyldem napisały i wydały w modelu self-publishingu książkę „Podróż przez świat testowania”, która w lekki i zabawny sposób uczy testowania. Książka pomogła już setkom osób w przebranżowieniu się i wejściu do IT.",
        title: "Senior Tester at FlexDev - Future-proof Your Business",
        linkedin: "https://www.linkedin.com/in/natalia-pawlak-b9262b57/",
      },
      {
        id: "karolinap",
        image: require("../Mentors/images/karolinap.jpg").default,
        name: "Karolina Pawłowska",
        description:
          "Aktywnie działa w środowisku IT i game dev od paru ładnych lat. Pracowała w różnych firmach i zespołach przy wielkich i małych aplikacjach. Najbardziej lubi dbać o jakość, szczególnie testować eksploracyjnie i dzielić się tą pasją z innymi. Jest fanką gier planszowych i komputerowych, które także testuje wspierając w ten sposób niezależnych twórców gier.\n" +
          "\nW 2020 roku razem z Natalią Pawlak stworzyły markę Fun with bugs. Pod tym szyldem napisały i wydały w modelu self-publishingu książkę „Podróż przez świat testowania”, która w lekki i zabawny sposób uczy testowania. Książka pomogła już setkom osób w przebranżowieniu się i wejściu do IT.",
        title: "Senior QA, trenerka IT i ekspertka ds. jakości oprogramowania",
        linkedin: "https://www.linkedin.com/in/karolina-paw%C5%82owska-70558678/",
      },
      {
        id: "krzysztofk",
        image: require("../Mentors/images/krzysztofk.jpg").default,
        name: "Krzysztof Kanawka",
        description:
          "Dr inż. Krzysztof Kanawka, absolwent AGH, Fachhoschule Muenster i Imperial College London. Obecnie pełni funkcję prezesa zarządu spółki Blue Dot Solutions, która jest MŚP działającą w sektorze kosmicznym w Polsce. Działalność Krzysztofa koncentruje się głównie praktycznym zastosowaniu danych satelitarnych, czyli na aplikacjach telekomunikacyjnych, GNSS, EO oraz analizach technologicznych sektora kosmicznego. Ponadto, od 2016 roku Krzysztof i jego zespół wspierają inne startupy technologiczne w formie akceleratora biznesowego Space3ac. Od tego czasu akcelerator ten wsparł ponad 150 startupów technologicznych z całego świata. Akcelerator zazwyczaj działa w formie „equity-free” i zapewnia kontakty z przemysłem.",
        title: "Dr inż. prezes zarządu Blue Dot Solutions",
        linkedin: "https://www.linkedin.com/in/krzysztofkanawka/",
      },
      {
        id: "patrykj",
        image: require("../Mentors/images/patrykj.jpg").default,
        name: "Patryk Jar",
        description:
          "Od ponad 15 lat działa w branży informatycznej, dostarczając kompleksowe systemy informatyczne.\n" +
          "\n" +
          "Od 2021 roku odpowiedzialny za współpracę z polskimi uczelniami i środowiskiem start-upowym.\n" +
          "\n" +
          "Aktywnie organizował i wspierał wiele inicjatyw edukacyjnych (Code Camp, Code Club, Virtual Work Experience) i innowacyjnych projektów na pograniczu IT i nauki.\n" +
          "\n" +
          "Prócz swoich stricte profesjonalnych zainteresowań, Patryk chętnie posłucha o (mądrych) rozwiązaniach Smart City, Green Software czy szeroko rozumianej pop-kulturze.",
        title: "Lider Techniczny zespołu Innowacji, Kainos",
        linkedin: "https://www.linkedin.com/in/yarpo/",
      },
    //   {
    //     id: "lukaszp",
    //     image: require("../Mentors/images/lukaszp.jpg").default,
    //     name: "Łukasz Pelowski",
    //     description:
    //       "Manager z 20 letnim doświadczeniem w zarządzaniu małymi i średnimi przedsiębiorstwami.\n" +
    //       "Specjalizuje się w planowaniu i wdrażaniu strategii rozwoju, negocjacjach oraz budowaniu wartości firmy.\n" +
    //       "Pierwszą działalność założył na studiach. Później stworzył, dopracował i rozwijał wiele idei biznesowych o  o zróżnicowanym profilu.\n" +
    //       "Obecnie zaangażowany w rozwój spółek Secret Garden Group i Biuro56, inwestuje także na giełdzie.\n" +
    //       "Prywatnie kocha boks, wspinaczkę i podróże. Ponadto lubi czytać i uczyć się języków obcych.\n" +
    //       "Entuzjasta dynamicznego podejścia do życia.",
    //     title: "General Director at SGG | Value investor | Mentor | Akademia Leona Koźmińskiego",
    //     linkedin: "https://www.linkedin.com/in/%C5%82ukasz-pelowski-79089020/",
    //   },
    //   {
    //     id: "jaroslaww",
    //     image: require("../Mentors/images/jaroslaww.jpg").default,
    //     name: "Jarosław Wulnikowski",
    //     description:
    //       "Programista-manager z 10 letnim doświadczeniem. W trakcie swojej kariery przeszedł przez wiele organizacji (startupy, agencje marketingowe, małe i średnie firmy, korporacje). Obecnie zajmuje się budowaniem i zarządzaniem zespołami programistycznymi oraz doradzaniem firmom w zakresie procesu wytwarzania oprogramowania. Aktualnie pracuje jako Principal software engineer w S&P Global gdzie odpowiada za kilkunastoosobowy zespół programistów oraz rozwija własną firmę Cappibare, gdzie wytwarza nowoczesne strony internetowe.",
    //     title: "Principal Software Engineer / Team Leader w S&P Global",
    //     linkedin: "https://www.linkedin.com/in/jaros%C5%82aw-wulnikowski-8523a57b",
    //   },
    //   {
    //     id: "wojciechl",
    //     image: require("../Mentors/images/wojciechl.jpg").default,
    //     name: "Wojciech Leonowicz",
    //     description:
    //       "Wspiera startupy w ich rozwoju, aktywnie uczestnicząc w lokalnych, krajowych i międzynarodowych mechanizmach wsparcia. Brał udział w akceleracji 150+ zespołów, które pracowały z ponad 20 dużymi przedsiębiorstwami i korporacjami.",
    //     title: "Bridging startup tech supply with business demands",
    //     linkedin: "https://www.linkedin.com/in/wojciech-leonowicz-61500b145/",
    //   },
    //   {
    //     id: "ewam",
    //     image: require("../Mentors/images/ewam.jpg").default,
    //     name: "Ewa Marczewska",
    //     description:
    //       "Dociekliwa specjalistka Data Science oraz artystka. Z wykształcenia urbanistka i filozofka. Wiele lat pracy przy projektach planistycznych, partycypacyjnych i edukacyjnych. Skupia się głównie na wizualizacji i analizie procesów zarówno biznesowych, jak i społecznych. Dodatkowo bardzo ważna jest dla niej praca społeczna i wykorzystanie nowych technologii w zakresie AI for Good. Aktywnie działa na rzecz promocji kobiet w każdej dziedzinie, szczególnie w AI, współzałożycielka WiMLDS (Women in Machine Learning & Data Science) Trójmiasto. W wolnym czasie, gdy nie czyta, to maluje, zwiedza świat na rowerze oraz tworzy muzykę.",
    //     title: "Data Visualization | AI Ethics | Data Storytelling | Data Science",
    //     linkedin: "https://www.linkedin.com/in/ewamarczewska",
    //   },
      {
        id: "kamilt",
        image: require("../Mentors/images/kamilt.jpg").default,
        name: "Kamil Tomaszewski",
        description:
          "Psycholog, doradca zawodowy, psychoterapeuta poznawczo-behawioralny w trakcie certyfikacji. Członek Polskiego Towarzystwa Terapii Poznawczo-Behawioralnej.\n" +
          "W swojej pracy terapeutycznej zajmuje się psychoterapią indywidualną osób dorosłych, zmagających się z różnymi trudnościami natury psychicznej i emocjonalnej. Zainteresowania zawodowe dotyczą: zaburzeń lękowych, afektywnych, osobowości, uzależnień.  Swoją pracę poddaje regularnej superwizji.\n" +
          "Tytuł magistra psychologii uzyskał na Uniwersytecie SWPS w Sopocie, z którym jest zawodowo związany od 2016 roku. Prowadzi zajęcia z zakresu psychologii klinicznej i zdrowia: dynamikę procesów grupowych, wywiad psychologiczny, umiejętności psychologiczne, psychopatologię. Wspiera liczne projekty badawcze, wdrażając rozwiązania przyjazne dla nauki i pracując nad własnym projektem doktoranckim. Ukończył studia z zakresu doradztwa zawodowego i job-coachingu. Prowadzi szkolenia z zakresu umiejętności miękkich. Ukończył szkolenia z zakresu terapii schematów, terapii samouszkodzeń, dialogu motywującego, kurs Terapii Poznawczej Opartej na Uważności (MBCT). Doświadczenie zawodowe zdobywał m.in. w Wojewódzkim Szpitalu Psychiatrycznym im. Prof. Tadeusza Bilikiewicza, Hospicjum im. Ks. E. Dutkiewicza w Gdańsku, Centrum Integracja, Placówka Wsparcia Rodziny i Dziecka – Światłowcy.",
        title: "Wykładowca na Uniwersytecie SWPS w Sopocie, kierownik Pracowni Badań Psychologicznych, psycholog, psychoterapeuta",
        linkedin: " ",
      },
    //   {
    //     id: "annaz",
    //     image: require("../Mentors/images/annaz.jpg").default,
    //     name: "Anna Zielińska",
    //     description:
    //       "Jest Project Managerem z ponad 16-letnim doświadczeniem w zarządzaniu projektami i współpracy z innowacyjnymi polskimi i zagranicznymi firmami i start-upami. Specjalizuje się w projektach międzynarodowych. Ściśle współpracuje z przedstawicielami biznesu i nauki pełniąc rolę pośrednika między tymi dwoma światami. Od 2021 r. wspiera europejskie start-upy w ramach międzynarodowej akceleracji URBAN TECH.  Ukończyła kierunek biznes międzynarodowy w Sydney’s International College, a także biznes i marketing z Australian University ACU National",
    //     title: "Project Manager",
    //     linkedin: " ",
    //   },
    //   {
    //     id: "laszyk",
    //     image: require("../Mentors/images/laszyk.png").default,
    //     name: "Łukasz Aszyk",
    //     description: "Absolwent MsC Data Science na City, University of London oraz Wydziału Zarządzania Politechniki Gdańskiej. Na co dzień zajmuje się konsultingiem i wdrażaniem rozwiązań chmurowych. Przez 6 lat zdobywał doświadczenie we własnej firmie konsultingowej w zakresie zarządzania projektami. Interesuje się rozwiązaniami w oparciu o dane od ponad 5 lat. Laureat dwoch wewnetrznych hackathonow (pollen.co) - algorytm klasyfikacji kandydatów oraz automatyzacja procesu bookingowego. Po godzinach biega z psem, czyta nowinki z zakresu technologii i przygotowuje do kolejnych certyfikacji z rozwiązań cloudowych (ostatnio AWS Solutions Architect Associate). Tech stack: SQL, Python, Snowflake (SnowPro certified), AWS (Cloud Practitioner certified), dbt, Looker (LookML certified).",
    //     title: "Senior Analytics Engineer, Atos",
    //     linkedin: "https://www.linkedin.com/in/lukaszaszyk/"
    //   },
    //   {
    //     id: "janc",
    //     image: require("../Mentors/images/janc.jpg").default,
    //     name: "dr inż. arch. Jan Cudzik",
    //     description: "Jest kierownikiem badań w spółce OutlineAI zajmującej się automatyzacją procesów analiz oraz projektowania w architekturze oraz adiunktem w Katedrze Architektury Miasta i Przestrzeni Nadwodnych oraz Kierownikiem Laboratorium Cyfrowych Technologii, na Wydziale Architektury Politechniki Gdańskiej. Obecnie prowadzi szeroko zakrojone badania naukowe dotyczące między innymi architektury kinematycznej, cyfrowych technik wspomagających projektowanie architektoniczne, cyfrowej fabrykacji, form sztucznej inteligencji w architekturze i sztuce. \n",
    //     title: "COO in OutlineAI",
    //     linkedin: "https://www.linkedin.com/in/jan-cudzik/"
    //   },
    //   {
    //     id: "agatap",
    //     image: require("../Mentors/images/agatap.jpg").default,
    //     name: "Agata Piekut",
    //     description: "Specjalizuje się w budowaniu systemów zarządzania danymi, przede wszystkim na rynku medycznym oraz organizacji pozarządowych. Od 2016 roku prowadzi Health Action Tank, wspierającą rozwój rynku technologii medycznych. Jest ekspertką Światowej Organizacji Zdrowia ds. technologii zdrowotnych, aktualnie jako członkini grupy fokusowej WHO ds. sztucznej inteligencji w zdrowiu. Współpracowała przy projektach związanych z etyką w nowych technologiach m.in. IEEE Global A/IS Ethics Initiative, Digital Impact Uniwersytetu Stanforda, HIMSS (USA). Członkini BKDS ds. Cyfryzacji Miasta Stołecznego Warszawy.",
    //     title: "Konsultantka Salesforce, PwC | Ekspertka w obszarze technologii medycznych",
    //     linkedin: "https://pl.linkedin.com/in/agatapiekut"
    //   },
    //   {
    //     id: "jakubz",
    //     image: require("../Mentors/images/jakubz.jpg").default,
    //     name: "Jakub Zając",
    //     description: "Pasjonat technologii i zmieniania świata na lepsze. Aktualnie wspiera szefa pionu operacyjnego Financial Crime Unit PwC jako Ekspert ds. Transformacji. Szuka możliwości optymalizacji organizacji i facylituje wdrażanie zmian w życie. Angażuje się też w dbanie o to, by biznes był jak najbliższym ludziom, którzy go tworzą. Po godzinach prowadzi z bratem małą firmę w branży edukacyjnej, wspierając maturzystów w walce o indeksy na wymarzonych uczelniach i kierunkach.",
    //     title: "Financial Crime Unit PwC jako Ekspert ds. Transformacji",
    //     linkedin: "https://www.linkedin.com/in/jakzaj/"
    //   },
    //   {
    //     id: "magdak",
    //     image: require("../Mentors/images/magdak.jpg").default,
    //     name: "Magda Kwoska",
    //     description: "Magda posiada doświadczenie w rozwijaniu oraz wdrażaniu innowacji technologicznych. Od kilkunastu lat wspiera powstawanie i rozwój nowych rozwiązań, zarówno wewnątrz korporacji jak i startupów. Jest zaangażowana we wsparcie mentoringowe dla startupów jak i przedsiębiorstw społecznych. \n" +
    //       "Magda zajmowała się w PwC współpracą ze startupami prowadząc program akceleracyjny Startup Collider a obecnie zajmuje się programem innowacji pracowniczych PwC Innovation@Poland. Prowadziła także inkubatory społeczne, programy grantowe realizowane dla Ministerstwa Rozwoju, we współpracy z organizacjami pozarządowymi, które miały na celu wsparcie innowacyjnych pomysłów społecznych.\n" +
    //       "Prowadziła również program akceleracyjny dla startupów dla funduszu VC oraz stała po drugiej stronie - prowadząc swój startup.\n" +
    //       "Zajmowała się Business Developmentem w startupie iTaxi, wprowadzała nową markę na rynek - Socatots, ogólnoświatową sieć szkółek oferujących zajęcia ogólnorozwojowe dla małych dzieci.\n" +
    //       "Z wykształcenia farmaceutka, która w pewnym momencie postanowiła zmienić swoją karierę zawodową.\n",
    //     title: "Innovation Manager at PwC Polska",
    //     linkedin: "https://www.linkedin.com/in/mkwoska/"
    //   },
    //   {
    //     id: "magdalenab",
    //     image: require("../Mentors/images/magdalenab.jpg").default,
    //     name: "dr hab. Magdalena Błażek",
    //     description: "Profesor uczelni, psycholog, prodziekan ds. jakości kształcenia i praktyk studenckich Wydziału Nauk o Zdrowiu, kierownik Katedry Psychologii i Zakładu Badań nad Jakością Życia Gdańskiego Uniwersytetu Medycznego oraz kierunku psychologia zdrowia. Jej obszar zainteresowań to: psychologia zdrowia, psychologia jakości życia, osobowości, diagnoza osobowości, osobowościowe korelaty funkcjonowania człowieka i rodziny w doświadczaniu kryzysów normatywnych i nienormatywnych.",
    //     title: " ",
    //     linkedin: "https://www.linkedin.com/in/magdalena-b%C5%82a%C5%BCek-28903a27/"
    //   },
    //   {
    //     id: "pawels",
    //     image: require("../Mentors/images/pawels.jpg").default,
    //     name: "Paweł Szymkowiak",
    //     description: "Od 18 lat pomagam organizacjom usprawniać komunikację zdalną. Byłem jednym z pierwszych liderów wprowadzających komunikacje video w globalnych korporacjach ( Fortune 100 )– gdy video było jeszcze dostępne tylko dla stacji telewizyjnych i niszowych startupów. Rozpoznaje trendy i wykorzystuje wiedzę do usprawnienia organizacji. W pracy kieruje się analizą podstaw działania świata i społeczeństw i na podstawie tego wyznaczam możliwości danych rozwiązań technicznych. Głęboko wierzę że współczesne społeczeństwa i ludzie jako istoty nie różnią się od tych opisywanych przez Shakespeare’a – kierują nami te same emocje oraz odruchy.",
    //     title: "IT Senior Manager at PwC",
    //     linkedin: "https://www.linkedin.com/in/pawelszymkowiak/"
    //   },
    //   {
    //     id: "michalo",
    //     image: require("../Mentors/images/placeholder.svg").default,
    //     name: "dr Michał Olech",
    //     description: "Z wykształcenia matematyk, od wielu lat zajmuje się analizą danych w kontekście nauk społecznych: najpierw jako freelancer w obszarze doradztwa biznesowego, a później jako pracownik naukowo-dydaktyczny w Instytucie Psychologii UG oraz Katedry Psychologii Zdrowia GUMed. Autor lub współautor wielu narzędzi psychometrycznych. Brał udział w procesach walidacji psychometrycznych wielu narzędzi, w tym np. polskiej wersji skali Stanford-Binet. Programuje w języku R oraz używa go w pracy dydaktycznej ucząc studentów statystyki i analizy danych.\n",
    //     title: " ",
    //     linkedin: " "
    //   },
      {
        id: "pamela",
        image: require("../Mentors/images/pamela_k.jpeg").default,
        name: "Pamela Krzypkowska",
        description:
          "Director of Research and Innovation w Ministerstwie Cyfryzacji. AI, Cloud Architect. AI Ethics Board Member w Cogniman. Wykładowczyni na Uniwersytecie Koźmińskiego, a wcześniej także Cloud Solution Architect (Data & AI) w Microsoft.",
        title: "Director of Research and Innovation w Ministerstwie Cyfryzacji",
        linkedin: "https://www.linkedin.com/in/pamela-krzypkowska/",
      },
    //   {
    //     id: "pawelk",
    //     image: require("../Mentors/images/pawelk.png").default,
    //     name: "Paweł Krolak",
    //     description:
    //       "Biznesowo-techniczna sesja inspiracyjna bazująca na doświadczeniach z międzynarodowych projektów realizowanych przez Microsoft Industry Solutions. Podczas sesji zostaną przytoczone przykłady, inspiracje i dobre praktyki dotyczące rozwiązań IT tworzonych w celu wsparcia ludzi oraz środowiska. Celem sesji jest zainspirowanie uczestników hackathonu na jakie aspekty warto zwrócić uwagę na etapie identyfikacji potrzeb biznesowych, jak również planowania oraz technicznej implementacji rozwiązania, aby osiągnąć jak największy pozytywny efekt. Podczas sesji zostaną również zaprezentowany program, w ramach którego Microsoft wspiera startup’y zapewniając wsparcie mentorów, udostępniając szkolenia, innowacyjne usługi oraz inne korzyści.",
    //     title: "Solution Architect, Microsoft",
    //     linkedin: "https://www.linkedin.com/in/pkrolak",
    //   },
    //   {
    //     id: "soon",
    //     image: require("../Mentors/images/rsz_coming_soon.png").default,
    //     name: " ",
    //     description: " ",
    //     title: " ",
    //     linkedin: " ",
    //   },
    // ],
    // prelegentsTitle: "Prelegenci i jury",
    // prelegents: [
    //   {
    //     id: "marcin",
    //     image: require("../Mentors/images/marcin_m.jpeg").default,
    //     name: "Marcin Młyński",
    //     description:
    //       "Entuzjasta nowych technologii, koncepcji Internet of Things oraz świata startupów. Koordynator wielu biznesowych wydarzeń dla setek osób oraz organizator kilkudziesięciu hackathonów. Co-founder & CEO CODE:ME, jednej z największych polskich szkół programowania oraz założyciel HACKER:SPACE Trójmiasto, otwartej technologicznej przestrzeni, poświęconej tworzeniu i rozwojowi innowacyjnych projektów. Hackerspace Trójmiasto jest obecnie jedną z największych technologicznych społeczności w północnej Polsce. Uczestnik PFR School of Pioneers, realizowanego we współpracy z University of Cambridge, międzynarodowego programu dedykowanego przedsiębiorcom i wizjonerom w obszarze nowych technologii.",
    //     title: "CEO, CODE:ME, Founder Hackerspace Trójmiasto",
    //     linkedin: "https://www.linkedin.com/in/marcin-mlynski/",
    //   },
    //   // {
    //   //   id: "monikas",
    //   //   image: require("../Mentors/images/monikas.png").default,
    //   //   name: "Monika Stokfisz",
    //   //   description:
    //   //     "Monika Stokfisz jest Koordynatorką Projektów i HR managerem, która przez całą swoją karierę zawodową podejmowała się różnorodnych zadań administracyjnych oraz zarządzania inicjatywami. Obecnie specjalizuje się w planowaniu, organizowaniu i administrowaniu projektów oraz działań HR, a także wykonuje różnorakie obowiązki operacyjne w Invest in Pomerania – między innymi jest koordynatorem projektów szkoleniowych w ramach Invest in Pomerania Academy. Dzięki wieloletniemu doświadczeniu i nietypowemu poczuciu humoru swobodnie współpracuje z zespołami na różnych płaszczyznach. Największą satysfakcję daje jej to, że ma realny wpływ na rozwój gospodarki Pomorza. Prywatnie matka kotów i malarka (oszukana, bo po numerach, ale zawsze)",
    //   //   title: "Koordynator Projektów i HR manager, Invest in Pomerania",
    //   //   linkedin: "https://www.linkedin.com/in/monika-stokfisz/",
    //   // },
    //   {
    //     id: "kacperk",
    //     image: require("../Mentors/images/kacperk.jpg").default,
    //     name: "Kacper Kostecki",
    //     description:
    //       "Na co dzień Linux engineer, który stara się aby infrastruktura zawsze była \"up and running\". Prowadzi firmę, która pomaga się odnaleźć zawodowo w ogromnym świecie IT poprzez dopasowywanie przyszłych pracowników wymagań klientów. Zielony dres i zielony Jeep to jego znaki rozpoznawcze. Zawsze otwarty na nowe doświadczenia i wyzwania.",
    //     title: "Linux Engineer",
    //     linkedin: "https://www.linkedin.com/in/%E2%9A%9B%EF%B8%8F-kacper-%F0%9F%92%BB-kostecki-236250110/",
    //   },
    //   {
    //     id: "wojciechh",
    //     image: require("../Mentors/images/wojciechh.jpg").default,
    //     name: "Wojciech Herisz",
    //     description:
    //       "Na co dzień Tech Lead z programistycznym zacięciem, doskonale łączy technologię z ludzkim podejściem. Jego pasja to psychologia evidence-based, dzięki której skupia się na ludziach jako największej wartości w organizacji. Wykorzystując wiedzę z zakresu psychologii, pomaga zrozumieć, dlaczego działamy w taki a nie inny sposób, prowadząc ludzi do sukcesów. Autor szkoleń i warsztatów z kompetencji zarówno miękkich jak i technicznych.",
    //     title: "Tech Lead, autor szkoleń i warsztatów",
    //     linkedin: " ",
    //   },
    //   {
    //     image: require("../Mentors/images/sergiuszr.png").default,
    //     name: "Sergiusz Ryczel",
    //     description:
    //       "Bazując na swoim doświadczeniu i umiejętnościach, prowadzę warsztaty z zakresu skutecznej komunikacji i wystąpień publicznych oraz szkolenia medialne. Pomagam w tworzeniu własnego video marketingu w ramach strategii Personal Branding lub Employer Branding. Moimi klientami są zarówno przedstawiciele biznesu korporacyjnego jak i mniejsi przedsiębiorcy oraz wszyscy Ci, którzy między innymi za pośrednictwem kamery i internetu promują swoje usługi. Współpracuję też z najmłodszymi lub starszymi sportowcami szkoląc ich w zakresie umiejętności kontaktu z mediami i pracy przed kamerą.",
    //     title:
    //       "Prezenter, komentator sportowy, dziennikarz, konferansjer, trener medialny, motywator, organizator rozrywki",
    //     linkedin: "https://www.linkedin.com/in/sergiusz-ryczel/",
    //   },
    //   {
    //     id: "radoslaww",
    //     image: require("../Mentors/images/radoslaww.jpg").default,
    //     name: "Radosław Wika",
    //     description:
    //       "Z wykształcenia ekonomista, posiada kilkunastoletnie doświadczenie w branży finansowej potwierdzone realizacją kilkudziesięciu projektów inwestycyjnych oraz doświadczeniem w zarządzaniu zespołami projektowymi. Ekspert w zakresie pozyskiwania funduszy, w tym europejskich. Praktyk w transferze technologii oraz w realizacji projektów B+R. Obecnie pełni obowiązki Dyrektora Gdańskiego Parku Naukowo-Technologicznego oraz jest członkiem Rady Pomorskiego Klastra ICT Interizon.",
    //     title: "Dyrektor Gdańskiego Parku Naukowo - Technologicznego",
    //     linkedin: "https://www.linkedin.com/in/rados%C5%82aw-wika-76a3b788/",
    //   },
    {
      id: "mariakt",
      image: require("../Mentors/images/mariakt.jpg").default,
      name: "Maria Koss-Tomaszewska",
      description:
        "Z wykształcenia prawniczką, a z serca kreatywną duszą, w Into the Unknown studio pełnię rolę specjalistki ds. marketingu. Moja zdolność do logicznego myślenia, wspierana przez wykształcenie prawnicze, pozwala mi podejść do strategii marketingowych w sposób pragmatyczny, jednocześnie moja kreatywność inspiruje mnie do poszukiwania innowacyjnych rozwiązań.",
      title: "",
      linkedin: " ",
    },
    {
      id: "sebastianc",
      image: require("../Mentors/images/sebastian cygiert.jpg").default,
      name: "Sebastian Cygiert",
      description:
        "Postdoc w Ideas NCBR i adiunkt na Politechnice Gdańskiej. Poprzednio pracował jako ekespert naukowy w Amazon. Specjalista AI z bogatym doświadczeniem w nauce i przemyśle.",
      title: "Postdoctoral Researcher",
      linkedin: "https://www.linkedin.com/in/sebastiancygert",
    },
    {
      id: "dawidj",
      image: require("../Mentors/images/DawidJozwiak.jpeg").default,
      name: "Dawid Jóźwiak",
      description:
        "Od 2006 roku zajmuję się informatyką i szeroko pojętą technologią. Moje główne obszary specjalizacji to chmura, cyberbezpieczeństwo i rozwiązania infrastrukturalne. Zawodowo jestem SysAdminem typu 'człowiek-orkiestra'. Wielokrotnie pracowałem z zaawansowanymi rozwiązaniami IT dla gigantów technologicznych, takich jak Amazon Web Services i dla dostawców usług zarządzanych będących oficjalnymi partnerami Microsoft. Moje podejście do cyberbezpieczeństwa jest bardzo praktyczne i wierzę, że bezpieczeństwo powinno być czymś powszechnym i przystępnym dla każdej osoby, zwłaszcza w obecnej dobie nadmiernie skomplikowanych słów i definicji.",
      title: "Inżynier Systemów Informatycznych & Specjalista ds. Cyberbezpieczeństwa",
      linkedin: "https://www.linkedin.com/in/dawidjozwiak/",
    },
    {
      id: "kamilh",
      image: require("../Mentors/images/Kamil_Homernik.jpeg").default,
      name: "Kamil Homernik",
      description:
        "Full-stack JavaScript Deweloper i entuzjasta Startupów. Dzielę się swoją wiedzą i doświadczeniem w biznesie, technologii, programowaniu i rolnictwie.",
      title: "Full-stack JavaScript Developer and Startups enthusiast",
      linkedin: "https://www.linkedin.com/in/kamilhomernik/",
    },
    {
      id: "bartoszp",
      image: require("../Mentors/images/b-puszczyk.jpg").default,
      name: "Bartosz Puszczyk",
      description:
        "Projektant UX/UI z ponad dekadą doświadczenia, zdobytego podczas pracy dla firm w Polsce i na świecie. Dąży do wnoszenia wartości poprzez zrozumienie potrzeb użytkowników i przekształcanie ich w funkcjonalne, atrakcyjne produkty. Mówca i trener, pasjonat nowych technologii i street artu. Autor książki 'Rapid design any app from concept to prototype'.<br/><br/> W czym mogę pomóc: <br/><br/>Mogę pomóc zrozumieć perspektywę użytkownika, dopracować wartości w rozwiązaniu problemu i zaproponować metody, które ułatwią obsługę produktu.",
      title: "UX/UI Designer at Scalac",
      linkedin: "https://www.linkedin.com/in/b-puszczyk/",
    },
    {
      id: "ewak",
      image: require("../Mentors/images/Ewa Kryger.jpg").default,
      name: "Ewa Kryger",
      description:
        "Dociekliwa specjalistka Data Science oraz artystka. Z wykształcenia urbanistka i filozofka. Wiele lat pracowała przy projektach planistycznych, partycypacyjnych i edukacyjnych. Skupia się głównie na wizualizacji i analizie procesów zarówno biznesowych, jak i społecznych. Dodatkowo bardzo ważna jest dla niej praca społeczna i wykorzystanie nowych technologii w zakresie AI for Good. Aktywnie działa w obszarze 3 sektora, współzałożycielka WiMLDS (Women in Machine Learning & Data Science) Trójmiasto. Założycielka Fundacji Art Hacks AI, której główną misją będzie tłumaczenie zagadnień związanych z nowymi technologiami, językiem emocji poprzez sztukę. W wolnym czasie, gdy nie czyta, to maluje, zwiedza świat na rowerze oraz tworzy muzykę.",
      title: "Delivery Management in AI | TOP100 Women in Data Science | Project Management | Team-building Facilitation | Art Meditation | AI Ethics | Art Tech",
      linkedin: "https://www.linkedin.com/in/ewakryger/",
    },
    {
      id: "rital",
      image: require("../Mentors/images/Rita Lywczek.png").default,
      name: "Rita Ływczek",
      description:
        "Małgorzata Rita Łyczywek na co dzień pracuje jako Senior Software Engineer w Wizards of the Coast, od lat prowadzi bloga flynerd.pl - pisze o technologiach, uczy programowania i pełni rolę Head of Tech w CODE:ME",
      title: "Head of Technology CODE:ME Foundation | Software Engineer️‍ D&D Beyond | Tech Blogger Mentor | Node.js • TypeScript • AWS • Python",
      linkedin: "https://www.linkedin.com/in/ritalyczywek/",
    },
    {
      id: "karmenr",
      image: require("../Mentors/images/Karmen Radtke.webp").default,
      name: "Karmen Radtke",
      description:
        "Na potrzebę imprez typu hackathon i gamejam opracowałam skuteczny system tworzenia prezentacji, który przyczynił się do niejednokrotnego zajęcia miejsca na podium przez mój zespół. Współpracowałam przy tworzeniu skutecznych prezentacji biznesowych pod pichte do dofinansowań. Na co dzień zajmuję się projektowaniem interfejsów i dbam o przekazywanie maksymalnej ilości informacji w najbardziej przystępny sposób. Chętnie pomogę przy tworzeniu prezentacji, podpowiem sprawdzone rozwiązania i dam feedback.",
      title: "UI & UX Designer I VR I Graphic Art Designer I Gamedev",
      linkedin: "https://www.linkedin.com/in/m-karmen-radtke/",
    },
    {
      id: "joannak",
      image: require("../Mentors/images/Joanna Kotowicz.jpg").default,
      name: "Joanna Kotowicz",
      description:
        "Menedżerka z 15-letnim doświadczeniem w obszarach inwestycji i rozwoju, pracująca w organizacjach na podstawie modelu rozwojowego ESG. Zwolenniczka transformacji energetycznej i zero waste. Współpracowała z sektorem energetycznym i produkcyjnym. Obecnie menedżerka w PKO Bankiem Polskim, gdzie wspiera przedsiębiorstwa w zakresie zrównoważonego rozwoju i finansowania zielonych inwestycji. Absolwentka Collegium Civitas w Warszawie, Politechniki Gdańskiej oraz Akademii Marynarki Wojennej. Ukończyła studia MBA Zarządzanie Polityką Energetyczną i Klimatyczną.",
      title: "MBA in energy and climate I Future Oriented Person | Zero-emission Oriented Manager | Sustainability Expert",
      linkedin: "https://www.linkedin.com/in/joannakotowicz/",
    },
    {
      id: "basiaj",
      image: require("../Mentors/images/basia_jagoda.jpg").default,
      name: "Basia Jagoda",
      description:
        "Basia jest managerka projektów PR dla branży technologicznej i interaktywnej. Zawodowo związana z branżą IT od 2013 – pracowała z łódzkimi software house’ami, obecnie w zespole agencji Linkleaders współtworzy projekty PR m. in. dla theprotocol.it. Z pochodzenia pomorzanka, z wyboru łodzianka. Reprezentuje the:protocol jako ambasadorka raportu „Polacy na cyfrowej równoważni. Cyfrowy ślad technologii 2024”",
      title: "PR dla branży IT",
      linkedin: "https://www.linkedin.com/in/basiajagoda/",
    },
    {
      id: "stanislaws",
      image: require("../Mentors/images/Stanislaw_Szultka.jpg").default,
      name: "Stanislaw Szultka",
      description:
        "Doświadczony menedżer z udokumentowaną historią pracy w branży think tanków. Profesjonalista w zarządzaniu produktem, posiadający umiejętności w zakresie negocjacji, planowania biznesowego, analizy, innowacji i przedsiębiorczości.",
      title: "Dyrektor Departamentu Rozwoju Gospodarczego w Urząd Marszałkowski Województwa Pomorskiego",
      linkedin: "https://www.linkedin.com/in/stanislawszultka/",
    },
    {
      id: "alicjagk",
      image: require("../Mentors/images/alicjagk.jpg").default,
      name: "Alicja Gaładyk-Korytko",
      description:
        `Na co dzień stara się łączyć kreatywność z analitycznym spojrzeniem. Obecnie zajmuje się szeroko rozumianym marketingiem marki the:protocol. Koordynuje obecność marki na eventach, jest zaangażowana w tworzenie komunikacji i  szeroko zasięgowych kampanii do użytkowników. Posiada doświadczenie w zarządzaniu projektami z pogranicza marketingu i IT. Ma za sobą wieloletnie doświadczenie w sektorze obsługi klienta, dzięki czemu patrzy na projekty z dużo szerszej perspektywy.
Edukację wyższą rozpoczęła od obronienia tytułu inżyniera biotechnologii ze specjalizacją roślinną, następnie zmieniła obszar i zrobiła studia magisterskie w Szkole Głównej Handlowej w Warszawie z zakresu e-biznesu i marketingu internetowego.
Prywatnie angażuje się w dodatkowe projekty IT, wychodząc z założenia, że jeśli czegoś nie umie, a jest jej potrzebne, to najwyższy czas spróbować się tego nauczyć.`,
      title: "Specjalistka ds. Marketingu w the:protocol",
      linkedin: "https://www.linkedin.com/in/alicjaga%C5%82adykkorytko/",
    },
    {
      id: "mateuszb",
      image: require("../Mentors/images/mateuszb.jpg").default,
      name: "Mateusz Bartoszewski",
      description: `Mateusz Bartoszewski, absolwent Wydziału Biologii UG.
Z wykształcenia biolog-parazytolog, który zgłębiał tajniki życia kleszczy. Obecnie zawodowo związany z Hevelianum, gdzie zajmuje się popularyzacją nauki i edukacją ekologiczną. W swojej pracy szczególnie mocno przygląda się przyrodzie Góry Gradowej i gdańskiego Brzeźna, a w kręgu jego największych zainteresowań znajdują się owady, rosnące tam „chwasty” i wodne stworzenia, które można spotkać w akwariach Hevelianum. W wolnych chwilach zwiedza inne światy podczas sesji RPG, wytęża mózg podczas grania w planszówki lub relaksuje się malując figurki do wyżej wymienionych.`,
      title: "Specjalista ds. Edukacji Ekologicznej w Hevelianum",
      linkedin: "https://www.linkedin.com/in/mateusz-bartoszewski-296b81197/",
    },
    {
      id: "monikan",
      image: require("../Mentors/images/monikan.jpg").default,
      name: "Monika Niegrzybowska",
      description: `Senior Frontend Developer w Sigma IT Poland, z ponad 6-letnim doświadczeniem w branży IT, gdzie skupia się na tworzeniu intuicyjnych, dużych w skali aplikacji webowych z mikrofrontendami na czele. Na co dzień pracuje z React.js, TypeScript, Jest. Po godzinach wspiera kobiety w środowiskach IT dzieląc się wiedzą programistyczną oraz jest jedną z programistek w projekcie Żeton w Hackerspace. Siły regeneruje grając w planszówki i praktykując jogę.`,
      title: "Senior Frontend Developer | React | JavaScript",
      linkedin: "https://www.linkedin.com/in/monikaniegrzybowska",
    },
    {
      id: "magdalenaj",
      image: require("../Mentors/images/magdalanaj.jpg").default,
      name: "Magdalena Jabłońska",
      description: `Ponad 25 lat temu zaczynała w dyplomacji, potem w branży eventowej i działach marketingu aż po sektor pozarządowy. W każdym z tych obszarów zajmowała się głównie budową wizerunku i tworzeniem treści. Jest całkiem niezłym copywriterem, kocha słowa, żonglowanie nimi oraz komunikowanie za ich pomocą wartościowych znaczeń. Urodzona networkerka – posiada moc łączenia ludzi wokół małych i dużych spraw, szczególnie spod znaku CSR-u.<br/><br/>
      W 2016 roku wróciła z Warszawy do rodzinnego Gdańska i całkowicie przeniosła swoje moce przerobowe do sektora trójmiejskich organizacji pozarządowych, zaczynając od koordynacji Gdańsk Business Run. Od 2018 roku współpracowała ze Stowarzyszeniem Morena jako trenerka w tematyce promowania młodzieżowych projektów społecznych oraz działalności spółdzielni uczniowskich. W styczniu 2021 dołączyła do Fundacji RC, gdzie współtworzy Kreatywny Dział PR jako specjalistka ds. komunikacji i budowania partnerstw oraz jako doradczyni w Gdańskim Centrum Organizacji Pozarządowych w zakresie tych dwóch zagadnień. 
Jest pomysłodawczynią Spółdzielni Socjalnej Zeroban, która przerabia
 niepotrzebne materiały reklamowe na torby i różne akcesoria. Najtrafniej pasuje do niej określenie „innowatorka zmian społecznych, która wymyśla, szkoli, doradza i sieciuje.” Jej aktualna zajawka to społeczna odpowiedzialność NGO, ISO 26000 oraz ESG.`,
      title: " ",
      linkedin: "https://www.linkedin.com/in/magdalena-jablonska/",
    },
    {
      id: "wojciechl",
      image: require("../Mentors/images/wojciechl.jpg").default,
      name: "Wojciech Leonowicz",
      description: `Aktywnie wspiera startupy na wczesnym etapie rozwoju uczestnicząc w lokalnych, krajowych i międzynarodowych mechanizmach wsparcia. Brał udział w akceleracji 200+ zespołów, które pracowały z ponad 20 dużymi przedsiębiorstwami i korporacjami.`,
      title: "Bridging startup tech supply with business demands",
      linkedin: "https://www.linkedin.com/in/wojciech-leonowicz/",
    },
    {
      id: "piotrc",
      image: require("../Mentors/images/piotrc.jpg").default,
      name: "Piotr Cichocki",
      description: `Programista .net z blisko dekadą doświadczenia w tworzeniu aplikacji webowych w środowiskach chmurowych.
W swojej pracy mocno stawia na perspektywę klienta i jego potrzeby. Tworzył rozwiązania dla branży turystycznej, inwestycyjnej, logistycznej, komorniczej oraz sądów.
W ostatnich latach współtworzy portal the:protocol od strony backendowej.`,
      title: "Senior Backend Developer the:protocol w Grupie Pracuj",
      linkedin: "https://www.linkedin.com/in/piotr-cichocki-b92b3791/",
    },
    {
      id: "maciejb",
      image: require("../Mentors/images/maciejb.jpg").default,
      name: "Maciej Budzisz",
      description: `Specjalista w zakresie cyfrowej dostępności z pięcioletnim stażem, który przeprowadził ponad 500 przeglądów witryn internetowych i aplikacji na urządzenia mobilne.
<br/>
Absolwent wydziału Zarządzania na Akademii Morskiej w Gdyni. Poza zawodowymi obowiązkami, żywi zamiłowanie do aktywności fizycznej i nauk o zachowaniu, co odzwierciedla się w jego roli przewodniczącego w Organizacji Aktywne Kosakowo.
<br/>
Jego percepcja aspektów technicznych oraz humanistycznych w dziedzinie dostępności wynika z synergii profesjonalnych kompetencji i prywatnych zainteresowań.`,
      title: "Ekspert ds. dostępności WCAG",
      linkedin: "https://www.linkedin.com/in/budziszmaciej/",
    },
    {
      id: "wojtekw",
      image: require("../Mentors/images/wojtek-wozniak.jpg").default,
      name: "Wojtek Woźniak",
      description: `No-Code Developer w <a href="https://www.tonik.com" target="_blank">https://www.tonik.com</a>, który magicznie zamienia pomysły w rzeczywistość bez pisania linijki kodu. Wojtek na codzień działa z narzędziami jak Webflow, Bubble.io, Make.com, Zapier i wszystkim, co nosi w sobie ducha "no-code". Jego projekty obejmują komercyjne produkty i strony internetowe dla firm o różnej skali działania, z szczególnym uwzględnieniem współpracy ze start-upami. Jego bazą jest przecudowna Gdynia, gdzie regeneruje siły biegając po lesie i plaży z jego psem, Plamą. Jego kulinarne guilty pleasures to pizza i donuty, które chętnie konsumuje, najlepiej na przemian.`,
      title: "Founder: gfluo.com / Webflow expert",
      linkedin: "https://www.linkedin.com/in/woz/",
    },
    ]
  },
}
